<template>
	<div class=''>
		<div class="w900 h300 bg-f9 mt91 mb40 pl34 pr23 flex col-center" style="margin: 0 auto;">
			<img src="" class="w286 h228 mr27">
			<div class="flex1 flex row-column h228 row-between pb28">
				<div class="fs24">标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题</div>
				<div class="fs18 color9D">内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内</div>
				<div class="fs18 color9D">2022-08-15</div>
			</div>
		</div>
		<div style="margin: 0 auto;" class="flex row-center">
			<div class="btn_box mr20">上一个</div>
			<div class="btn_box mr20">下一个 </div>
			<div class="btn_box">返回列表</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},

		components: {},

		created() {},

		mounted() {},

		methods: {
			
		}
	}
</script>
<style lang='scss' scoped>
	.btn_box {
		cursor: pointer;
		width: 100px;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #272727;
		background: #FFFFFF;
		border: 2px solid #272727;
		text-align: center;
	}

	.btn_box:last-child {
		background: #272727;
		color: #fff;
	}

	.btn_box:hover {
		background: #272727;
		color: #fff;
	}
</style>
