<template>
	<div class='pb40'>
		<img :src="img" alt="" style="margin: 0 auto;display: block;">
		<!-- <nextContent :downId='downId'/> -->
		<div style="margin: 0 auto;" class="flex row-center mt40">
			<div class="btn_box mr20" @click="getGoodsD(upId)">上一个</div>
			<div class="btn_box mr20" @click="getGoodsD(downId)">下一个 </div>
			<div class="btn_box" @click="$router.back()">返回列表</div>
		</div>
	</div>
</template>

<script>
	import { getGoodsD } from '@/api/index'
	import nextContent from '@/components/nextContent.vue'
	export default {
		data() {
			return {
				id: '',
				img: '',
				upId: '',
				downId: '',
				// 节流阀
				loading: false
			};
		},

		components: { nextContent },

		created() {
			this.$store.commit('editRouterName', this.$route.name)
			this.id = this.$route.params.id
			this.getGoodsD(this.id)
		},

		mounted() {},
		watch: {
			'$route': function(to, from) {
				this.id = this.$route.params.id
				this.getGoodsD(this.$route.params.id)
			}
		},
		methods: {
			async getGoodsD(id) {
				document.documentElement.scrollTop = 0;
				if (this.loading) {} else {
					if (id != 0) {
						this.loading = true
						const res = await getGoodsD({ id })
						this.loading = false
						this.img = res.data.productCont
						this.upId = res.data.upId
						this.downId = res.data.downId

					} else {
						this.$message.warning('没有产品了嗷')
					}
				}


			},
		}
	}
</script>
<style lang='scss' scoped>
	.btn_box {
		cursor: pointer;
		width: 100px;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #272727;
		background: #FFFFFF;
		border: 2px solid #272727;
		text-align: center;
	}

	.btn_box:last-child {
		background: #272727;
		color: #fff;
	}

	.btn_box:hover {
		background: #272727;
		color: #fff;
	}
</style>
